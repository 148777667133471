<template>
  <CookieModal />
  <router-view/>
</template>

<script>
import CookieModal from '@/components/modals/CookieModal';

export default {
  name: 'App',
  components: {
    CookieModal
  }
}
</script>
