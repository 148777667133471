<template>    
    <!-- Modal Adult -->
    <div class="modal fade" id="modalIsAdult" tabindex="-1" role="dialog" aria-labelledby="modalAdultoCenter" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Conteúdo Adulto</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Para continuar, você deve declarar ter <span style="font-weight: bold">18 anos ou mais</span> clicando no botão abaixo!
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Voltar</button>
            <!-- <a href="<?php echo (REWRITE_LINKS_ENABLE) ? 'shops?activity=1' : 'index.php?controller=shops&activity=1'; ?>"><button type="button" class="btn btn-primary">Confirmar</button></a> -->
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: "AdultModal"
  }
</script>
