<template>
    <!-- Modal termos -->
  <div class="modal fade" id="modalTermos" tabindex="-1" role="dialog" aria-labelledby="modalPrivac" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">TERMOS E CONDIÇÕES DE USO</h5>
          <a class="btn btn-white" data-bs-dismiss="modal" aria-label="Close" data-dismiss="modal"><i class="lni lni-close"></i></a>
        </div>
        <div class="modal-body" style="text-align: justify; font-size: 13px; font-weight: 500">
        ESTE TERMO APLICA-SE AO USO DO OBJETO DESCRITO NA CLÁUSULA I, A SEGUIR, DISPONIBILIZADO(S) PELA PLATAFORMA VITRINE DIGITAL E/OU SUA CONTROLADORA CGS | CG SISTEMAS LTDA. AO SE CADASTRAR, ESTARÁ DE ACORDO COM AS CONDIÇÕES E TERMOS DA PLATAFORMA.
        <br /><br />
        CLÁUSULA I - OBJETO
        <br /><br />
        OS SERVIÇOS DA PLATAFORMA VITRINE DIGITAL, ENVOLVEM APLICATIVOS, SOFTWARES, SITE, ESTUDOS TECNOLÓGICOS E/OU RECURSOS E SERVIÇOS LOCAIS E ON-LINE. 
        <br /><br />
        QUALQUER PESSOA, EMPRESA, ENTIDADE OU QUALQUER OUTRA DENOMINAÇÃO, DORAVANTE NOMINADA USUÁRIO, UTILIZANDO NOSSOS SERVIÇOS, ESTARÁ ACEITANDO E SUJEITO A ESTE TERMO DE CONDIÇÕES E POLÍTICAS DE USO. ESTA ACEITAÇÃO É INDISPENSÁVEL PARA A UTILIZAÇÃO DE NOSSOS SERVIÇOS.
        <br /><br />
        CLÁUSULA II – DO USO
        <br /><br />
        NOSSOS SERVIÇOS SÓ DEVEM SER UTILIZADOS PARA FINS LÍCITOS E AUTORIZADOS, NÃO DEVENDO, DE QUALQUER E NENHUMA FORMA, VIOLAR, APROPRIAR-SE OU INFRINGIR A LEI, DIREITOS NOSSOS, DE NOSSOS USUÁRIOS OU DE TERCEIROS. 
        <br /><br />
        1.  O USUÁRIO ADMINISTRADOR É RESPONSÁVEL PELA SUA CONTA, COMO TAMBÉM, DEVERÁ INFORMAR IMEDIATAMENTE QUANDO, SE HOUVER, USO NÃO AUTORIZADO E/OU VIOLAÇÃO DOS TERMOS DESTE;
        <br /><br />
        2.  O USUÁRIO CONCORDA EM DEFENDER E COOPERAR COM NOSSOS SERVIÇOS;
        <br /><br />
        3.  ESTE TERMO REGULARIZA O USO DA PLATAFORMA E SERVIÇO DISPONIBILIZADO AOS USUÁRIOS E PARCEIROS, VIA ON-LINE, SOLICITAR AQUISIÇÃO DE PRODUTOS E/OU SERVIÇOS, E AINDA, A EFETIVAÇÃO DE PAGAMENTOS DESTES;
        <br /><br />
        4.  NOSSOS SERVIÇOS TEM A FINALIDADE DE APROXIMAR USUÁRIOS E PARCEIROS PARA EFETIVAÇÃO DE NEGÓCIOS ENTRE SI, ONDE OS PARTES INTERESSADAS RECONHECEM QUE A PLATAFORMA VITRINE DIGITAL APENAS INTERMEDIA ESSES NEGÓCIOS, NÃO SENDO RESPONSÁVEL POR DISPONIBILIZAÇÃO DE PRODUTOS OU SERVIÇOS, EMBALAGENS OU ENTREGA DESTES E QUE ESTAS RESPONSABILIDADES ESTARÃO ACORDADOS ENTRE AS PARTES ENVOLVIDAS.
        <br /><br />
        5.  EM NENHUMA HIPÓTESE DEVE-SE:
        <br /><br />
        a)  COPIAR, MODIFICAR, ADAPTAR, DERIVAR E/OU ELABORAR TRABALHO, INTEFERIR, EXPLORAR E/OU DUPLICAR (OU AJUDAR TERCEIROS A FAZÊ-LO), COLETAR INFORMAÇÕES, DISTRIBUIR, FAZER ENGENHARIA REVERSA OU QUALQUER OUTRO METÓDO NÃO AUTORIZADO;
        <br /><br />
        b)  CRIAR E/OU UTILIZAR CONTAS USANDO MEIOS NÃO AUTORIZADOS E/OU AUTOMATIZADOS SEM PRÉVIA AUTORIZAÇÃO;
        <br /><br />
        6.  DO CADASTRO:<br />
        NOS RESERVAMOS O DIREITO DE RECUSAR A SOLICITAÇÃO DE CADASTRO E DE CANCELAR CADASTRO EXISTENTE, SE ESTIVEREM OU ENTRAREM EM DESACORDO COM ESTE TERMO DE CONDIÇÕES E POLÍTICAS DE USO.
        <br /><br />
        a)  O CADASTRO DEVE CONTER INFORMAÇÕES REAIS E PRECISAS, SEM NENHUM TIPO DE ARTIFÍCIO QUE POSSA TER OU LEVE A PARECER, INTUITO DE ENGANAR;
        <br /><br />
        b)  A IDADE PARA UTILIZAÇÃO DE NOSSOS SERVIÇOS SEGUE REGULAMENTAÇÃO DE LEI APLICADO A SUA REGIÃO/PAÍS.
        <br /><br />
        CLÁUSULA III – RESCISÃO E/OU BLOQUEIO
        <br /><br />
        QUALQUER VIOLAÇÃO DOS NOSSOS TERMOS PODERÁ ACARRETAR EM:
        <br /><br />
        1.  DESATIVAÇÃO DA CONTA;
        <br />
        2.  SUSPENSÃO DO ACESSO, TOTAL OU PARCIAL, A QUALQUER MOMENTO E SEM PRÉVIO AVISO;
        <br />
        3.  TORNAR O USUÁRIO INATIVO POR TEMPO DETERMINADO OU INDETERMINADO;
        <br />
        4.  DESAUTORIZAR O USO IMEDIATO;
        <br />
        5.  BANIR DE FORMA DEFINITIVA. 
        <br /><br />
        CLÁUSULA IV – DOS SERVIÇOS
        <br /><br />
        OS NOSSOS SERVIÇOS ESTÃO DISPONÍVEIS COM TODAS AS SUAS FUNÇÕES E RECURSOS, MAS PODEM:
        <br /><br />
        1.  SOFRER ALTERAÇÕES E/OU DESCONTINUAÇÕES DE FUNÇÕES;
        <br />
        2.  ACRESCENTAR E/OU DESATIVAR O USO EM PLATAFORMAS E DISPOSITIVOS;
        <br />
        3.  SER INTERROMPIDOS TEMPORARIAMENTE PARA ATUALIZAÇÕES E/OU REPAROS; 
        <br />
        4.  OU AINDA, PARADAS E INTERRUPÇÕES EM CASOS E/OU EVENTOS ALHEIOS A NOSSO CONTROLE E/OU VONTADE.  
        <br /><br />
        CLÁUSULA V – DISPOSIÇÕES GERAIS
        <br /><br />
        PRODUTOS, SERVIÇOS, DIREITOS E/OU OBRIGAÇÕES, PODEM SER CEDIDOS POR NÓS, EM DECORRÊNCIA DE FUSÃO, AQUISIÇÃO, VENDA E/OU QUALQUER OUTRA FORMA DE CESSAÇÃO, PARA OUTRA ENTIDADE/PROPRIETÁRIOS, NOS MODOS ENTRE AS PARTES ACORDADOS.
        <br /><br />
        OS DADOS DE USUÁRIOS E PARCEIROS, ESTÃO IMPLEMENTADOS DE FERRAMENTAS DE SEGURANÇA.
        <br /><br />
        PARÁGRAFO ÚNICO:<br />
        O USO COMERCIAL, MARCA, EXPRESSÃO, DOMÍNIO, CONTEÚDOS, PROGRAMAS, BANCO DE DADOS E DEMAIS ITENS DESCRITOS NA CLÁUSULA I, DESTE, SÃO DE NOSSA PROPRIEDADE E ESTÃO PROTEGIDOS POR LEIS E TRATADOS INTERNACIONAIS. O USO INDEVIDO E REPRODUÇÃO TOTAL OU PARCIAL É EXPRESSAMENTE PROIBIDO.
        <br /><br />
        SE ALGUMA CLÁUSULA NÃO ESTIVER CLARA OU NÃO FOI IMPOSTA, NÃO É CONSIDERADO RENÚNCIA DE DIREITO.
        <br /><br />
        PODEMOS ALTERAR ESTE TERMO OU ATUALIZÁ-LO A QUALQUER TEMPO, SEM PRÉVIO AVISO.
        <br /><br />
        VITRINE DIGITAL<br />
        Vitrinedigital.net
        <br /><br />
        CGS | CG SISTEMAS LTDA<br />
        cgsistemas.com.br
        <br /><br />
        <i class="lni lni-pause"></i>
        <br /><br />
        </div>
        <div class="modal-footer">
          <a class="btn btn-white" style="border: 1px solid #c7c7c7; border-radius: 50px;" data-bs-dismiss="modal" aria-label="Close" data-dismiss="modal">Fechar</a>
        </div>
      </div>
    </div>
  </div>
  <!-- /Modal -->
</template>

<script>
    export default {
        name: "TermsModal"
    }
</script>