<script setup>

    //import { watch } from "@vue/composition-api";
    // import { Skeletor } from 'vue-skeletor';
    import ShopItemSkeletor from '@/components/ShopItemSkeletor.vue'
    import ProductItemSkeletor from '@/components/ProductItemSkeletor.vue'
    import Header from '@/components/Header.vue'
    import Footer from '@/components/Footer.vue'
    import FilterCityModal from '@/components/modals/FilterCityModal.vue'
    import ProductItem from '@/components/ProductItem.vue'
    import ShopItem from '@/components/ShopItem.vue'
    //import SearchDataService from '@/services/SearchDataService';
    import { useStore } from 'vuex';
    import { onBeforeMount, ref, computed } from 'vue';
    //import InfiniteScroll from "infinite-loading-vue3";
    //console.log("From search: " + this.$VDIsPosting)

    const urlParams = new URLSearchParams(window.location.search); 

    //const app = getCurrentInstance();
    //let message = ref("");
    //let noResult = ref("");
    let loading = false;
    //let page = ref(1);        
    let max_items = ref(18);
    let resource = ref("products");
    //let count = ref(0);   
    let distance = ref(0);         

    const store = useStore();      

    const isPosting = computed(()=> store.state.isPosting );
    const page = computed(() => store.state.page.current);
    const loadMore = computed(() => store.state.page.loadMore);

    console.log("search: " + isPosting.value);
    //console.log("posting: " + isPosting.value);

    resource.value = urlParams.get('r');

    function IsMobile() {
        if( screen.width <= 760 ) {
            console.log("É mobile");
            return true;
        }
        else {
            console.log("Não é mobile");
            return false;
        }
    }

    if (IsMobile()){
        distance.value = 3000;
    } else {
        distance.value = 800;
    }    

    console.log("Distance: " + distance.value);

    function getRequestParams(query, resource, page, pageSize, activity, orderby){
        let params = {};
        
        if (query) {
            params['q'] = query;
        }

        if (resource) {
            params['res'] = resource;
        }
        
        if (page) {
            params['page'] = page;
        }
        
        if (pageSize) {
            params['pSize'] = pageSize;
        }

        if (pageSize) {
            params['activity'] = activity;
        }

        if (orderby) {
            params['orderby'] = orderby;
        }

        return params;
    }

    async function retrieveSearch() {   
        console.log("loadMore: " + loadMore.value);
        //console.log("currentPage: " + page.value);
        if (loading === true || loadMore.value === false) return;
        loading = true;

        const urlParams = new URLSearchParams(window.location.search);            
        const params = getRequestParams(
            urlParams.get("q"),
            urlParams.get("r"),
            page.value,
            urlParams.get("psize"),
            urlParams.get("activity")                
        );            

        resource.value = params.res;        
        
        await store.dispatch('SEARCH', params);            
        
        //page.value += 1;

        max_items = params.pSize;  

        loading = false;                           
    }

    const load = async($state) => {            
        await retrieveSearch();
        $state.loaded();
    }

    function onResourceChanged(value){
        console.log("Resource changed to: " + value);
        resource.value = value;
    }    

    //Events LifeCicle
    onBeforeMount(async ()=>{               
        console.log("OnBeforeMount raised");

        //console.log("from search: " + app.appContext.config.globalProperties.$VDIsPosting);
        
        await retrieveSearch();
    })            
    
</script>

<template>
    <FilterCityModal />
    <Header @onSearchSubmit="onSearchSubmit" @onResourceChanged="onResourceChanged" />     
    <!-- Main container Start -->  
    <div class="main-container section-padding">
      <div class="container">
        <div class="row">
          <!-- <div class="col-lg-3 col-md-12 col-xs-12 page-sidebar">
            <aside>
              <div class="widget categories">
                <h4 class="widget-title">Exibindo Loja(s).</h4>
              </div>
            </aside>
          </div> -->
          <div class="col-lg-12 col-md-12 col-xs-12 page-content">
               <!-- Result wrapper Start -->            
                <div class="tab-content">
                    <div id="grid-view" class="tab-pane fade active show">
                        <div :distance="distance" class="row results">                               
                            <!-- <infinite-scroll class="row" @infinite-scroll="retrieveSearch" 
                                :message="message"
                                :noResult="noResult"
                            > -->                                           
                                <ProductItem v-if="resource==='products'" />
                                <ShopItem v-else />                                                                 
                                <ProductItemSkeletor v-if="isPosting && resource === 'products'" :max-items="max_items" />                                               
                                <ShopItemSkeletor v-if="isPosting && resource === 'shops'" :max-items="max_items" />
                                
                                <infinite-loading class="pointLoad" :distance="distance" @infinite="load"></infinite-loading>
                        </div>                        
                    </div>
                    <div class="center-childrens" style="display: none;">
                        <a href="javascript:;" @click="load" class="btn btn-common" style="text-align: center !important;" type="button">Exibir mais produtos</a>
                    </div>
                </div>            
                <!-- Result wrapper End -->
          </div>
        </div>
      </div>
    </div>
    <!-- Main container End -->     
    <Footer />
</template>

<style>    

.center-childrens {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#hero-area .contents {
    padding: 30px 0 !important;
}

    /* .pointLoad {
        margin-top: -600;
    }

    @media only screen and (max-width: 600px) {
        .pointLoad {
            margin-top: 0;
        }
    } */

    /* .results::-webkit-scrollbar-track {
    border-radius: 4px;
    background: #333536;
    }
    .results::-webkit-scrollbar {
    border-radius: 4px;
    width: 8px;
    background: #7e7e7e;
    }
    .results::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #7e7e7e;
    } */
</style>