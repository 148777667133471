<template>
    <div style="display: inline-block;font-size: 16px;">Você está em: <strong>{{ cityField }}&nbsp;&nbsp;</strong> 
        <a href="javascript:;" @click="changeLocation()">Alterar</a>
    </div>
</template>

<script>

import { computed } from 'vue';
import { useCookies } from "vue3-cookies";
import { useStore } from 'vuex';

export default {
    setup() {

        const { cookies } = useCookies();
        const store = useStore();

        const cityField = computed(() => {
            if (!store.state.location.city || !store.state.location.uf) return '';
            return store.state.location.city + '-' + store.state.location.uf;
        });

        function changeLocation(){
            window.$('#localizacaoModal').modal('show');
        }

        return { 
            cookies,
            cityField,
            changeLocation
        };
    }
}
</script>
