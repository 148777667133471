import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import InfiniteLoading from "v3-infinite-loading";
//import "v3-infinite-loading/lib/style.css";
//import 'vue-skeletor/dist/vue-skeletor.css';
import '@/assets/css/skeletor.css';
import { Skeletor } from 'vue-skeletor';

const app = createApp(App)
app.use(store);
app.use(router);
app.component(Skeletor.name, Skeletor);
app.component('infinite-loading', InfiniteLoading);
app.mount('#app');

// .use(function(req, res, next) {
//     res.header("Access-Control-Allow-Origin", "*");
//     res.header("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
//     next();
// });
