<template>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4" v-for="index in props.maxItems" :key="index">
        <div class="featured-box">
        <figure>        
            <Skeletor class="skl-img" />
        </figure>
        <div class="feature-content">
            <div class="tg-product"></div>
            <h4><Skeletor class="skl-name" /></h4>
            <span><Skeletor width="50%" /> </span>
            <div class="tg-product" style="color: #999">
            <Skeletor  /> <br/>
            <Skeletor  />
            </div>
            <div class="btn-list">
            <Skeletor class="skl-button"/>
            </div>
        </div>
    </div>
  </div>  
</template>

<script setup>
//
    import { defineProps, onBeforeMount } from 'vue';

    const props = defineProps({
        maxItems: {
            type: Number,
            default: 1
        }
    });

    onBeforeMount(() => {
        console.log("ShopItemSkeletor: ");
    });

    //const { isPosting, max_items } = toRefs(props);
</script>

<style scoped>
    .skl-img {
        /*width: 100%;*/
        border-radius: 0%;
        height: 97px;
        margin: 0px;
    }

    .skl-name {
        height: 20px;
    }

    .skl-button {
        width: 100px;
        height: 45px;
        border-radius: 5px;
    }

</style>
