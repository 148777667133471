<template>

    <div v-for="item in products" :key="item.id_product" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div class="featured-box">
            <figure>
                <a :href="item.shop_link + item.product_link" target="_blank"><img class="img-fluid" :src="item.shop_link + item.image_link" alt=""></a>
            </figure>
            <div class="feature-content">
                <div class="tg-product">
                    <a>{{ item.name_category }}</a>
                </div>
                <h4><a :href="item.shop_link + item.product_link" target="_blank">{{ item.name }}</a></h4>
                <div class="tg-product">
                    <a :href="item.shop_link" target="_blank">{{ item.shopname }}</a>
                </div>
                <div class="btn-list">
                    <a v-if="item.price > 0" class="btn-price" :href="item.shop_link + item.product_link" target="_blank">{{ ( item.price === 0 ) ? '--' : 'R$ ' + item.price.toFixed(2).replace('.',',') }}</a>                     
                    <a class="btn btn-common" :href="item.shop_link + item.product_link" target="_blank">
                    <i class="lni-list"></i>
                    Ver detalhes
                    </a>
                </div>
            </div>
        </div>
    </div>

    <!-- <div v-if="shops.lenght==0" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <span v-if="activity">Nenhum Produto foi encontrado!"<b>{{ activity }}"</b></span>      
        <span v-if="!activity">Nenhuma Loja foi localizado com "<b>{{ query }}"</b></span>                      
    </div> -->

</template>

<script>

import { useStore } from 'vuex';
import { computed, onBeforeMount } from 'vue';

export default {
    setup() {
        
        const store = useStore();

        const products = computed(() => store.state.products );

        
        onBeforeMount(() => {
            console.log("carregou componente ProductItem ");            
        });

        return {
            products
        }
    }
}
</script>
