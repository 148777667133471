<template>
     <!-- Modal Privacidade -->
  <div class="modal fade" id="modalPrivacidade" tabindex="-1" role="dialog" aria-labelledby="modalPrivac" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">POLÍTICA DE PRIVACIDADE</h5>
          <a class="btn btn-white" data-bs-dismiss="modal" aria-label="Close" data-dismiss="modal"><i class="lni lni-close"></i></a>
        </div>
        <div class="modal-body" style="text-align: justify; font-size: 13px; font-weight: 500">
        A CGS | CG SISTEMAS LTDA E SUAS SUBSIDIÁRIAS TEM COMO PRIORIDADE A PRIVACIDADE E SEGURANÇA E NOS COMPROMETEMOS COM A TRANSPARÊNCIA DO TRATAMENTO DE DADOS PESSOAIS DOS NOSSOS USUÁRIOS/CLIENTES. ESTA POLÍTICA DE PRIVACIDADE ESTABELECE COMO É FEITA A COLETA, USO E TRANSFERÊNCIA DE INFORMAÇÕES DE CLIENTES OU OUTRAS PESSOAS QUE ACESSAM OU USAM NOSSOS SERVIÇOS.<br />
        AO UTILIZAR NOSSOS SERVIÇOS, VOCÊ ACEITA E ENTENDE QUE COLETAREMOS E USAREMOS SUAS INFORMAÇÕES PESSOAIS NAS FORMAS DESCRITAS, SOB AS NORMAS DE PROTEÇÃO DE DADOS (LGPD, LEI FEDERAL 13.709/2018), DAS DISPOSIÇÕES CONSUMERISTAS DA LEI FEDERAL 8078/1990 E AS DEMAIS NORMAS DO ORDENAMENTO JURÍDICO BRASILEIRO APLICÁVEIS.
        DESSA FORMA, A CGS | CG SISTEMAS LTDA, DORAVANTE DENOMINADA SIMPLESMENTE COMO “CGS”, INSCRITA NO CNPJ/MF SOB O Nº 03.361.256/0001-12, NO PAPEL DE CONTROLADORA DE DADOS, OBRIGA-SE AO DISPOSTO NA PRESENTE POLÍTICA DE PRIVACIDADE.
        <br /><br />
        1.  QUAIS DADOS COLETAMOS SOBRE VOCÊ E SUA FINALIDADE?
        <br /><br />
        NOSSO SITE COLETA E UTILIZA ALGUNS DADOS PESSOAIS SEUS, DE FORMA A VIABILIZAR A PRESTAÇÃO DE SERVIÇOS E APRIMORAR A EXPERIÊNCIA DE USO.
        <br /><br />
        1.1.  DADOS PESSOAIS FORNECIDOS PELO TITULAR (QUANDO HOUVER)
        <br /><br />
        • NOME COMPLETO<br />
        • CPF<br />
        • RAZÃO SOCIAL<br />
        • CNPJ<br /> 
        • E-MAIL<br />
        • NÚMERO DE TELEFONE<br />
        • DATA DE NASCIMENTO<br />
        • ENDEREÇO
        <br /><br />
        2.  COMO COLETAMOS OS SEUS DADOS?
        <br /><br />
        NESSE SENTIDO, A COLETA DOS SEUS DADOS PESSOAIS OCORRE DA SEGUINTE FORMA:
        OS DADOS SÃO COLETADOS NO MOMENTO DO CADASTRO.
        <br /><br />
        2.1.  CONSENTIMENTO
        <br /><br />
        É A PARTIR DO SEU CONSENTIMENTO QUE TRATAMOS OS SEUS DADOS PESSOAIS. O CONSENTIMENTO É A MANIFESTAÇÃO LIVRE E INFORMADA PELA QUAL VOCÊ AUTORIZA A CGS A TRATAR SEUS DADOS.<br />
        ASSIM, EM CONSONÂNCIA COM A LGPD, SEUS DADOS SÓ SERÃO COLETADOS, TRATADOS E ARMAZENADOS MEDIANTE PRÉVIO E EXPRESSO CONSENTIMENTO.<br />
        O SEU CONSENTIMENTO SERÁ OBTIDO DE FORMA ESPECÍFICA PARA CADA FINALIDADE ACIMA DESCRITA, EVIDENCIANDO O COMPROMISSO DE TRANSPARÊNCIA DA CGS PARA COM SEUS USUÁRIOS/CLIENTES, SEGUINDO AS REGULAÇÕES LEGISLATIVAS PERTINENTES.<br />
        AO UTILIZAR OS SERVIÇOS DA CGS E FORNECER SEUS DADOS PESSOAIS, VOCÊ ESTÁ CIENTE E CONSENTINDO COM AS DISPOSIÇÕES DESTA POLÍTICA DE PRIVACIDADE, ALÉM DE CONHECER SEUS DIREITOS E COMO EXERCÊ-LOS.
        A QUALQUER TEMPO E SEM NENHUM CUSTO, VOCÊ PODERÁ REVOGAR SEU CONSENTIMENTO.<br />
        É IMPORTANTE DESTACAR QUE A REVOGAÇÃO DO CONSENTIMENTO PARA O TRATAMENTO DOS DADOS PODE IMPLICAR A IMPOSSIBILIDADE DA PERFORMANCE ADEQUADA DE ALGUMA FUNCIONALIDADE DO SITE QUE DEPENDA DA OPERAÇÃO E TAIS CONSEQUÊNCIAS SERÃO INFORMADAS.<br />
        <br /><br />
        3.  QUAIS SÃO OS SEUS DIREITOS?
        <br /><br />
        A CGS ASSEGURA A SEUS USUÁRIOS/CLIENTES SEUS DIREITOS DE TITULAR PREVISTOS NO ARTIGO 18 DA LEI GERAL DE PROTEÇÃO DE DADOS. DESSA FORMA, VOCÊ PODE, GRATUITAMENTE E A QUALQUER TEMPO:
        <br /><br />
        - CONFIRMAR A EXISTÊNCIA DE TRATAMENTO DE DADOS, DE MANEIRA SIMPLIFICADA OU EM FORMATO CLARO E COMPLETO.
        <br /><br />
        - ACESSAR SEUS DADOS, PODENDO SOLICITÁ-LOS EM UMA CÓPIA LEGÍVEL SOB FORMA IMPRESSA OU POR MEIO ELETRÔNICO.
        <br /><br />
        - CORRIGIR SEUS DADOS, AO SOLICITAR A EDIÇÃO, CORREÇÃO OU ATUALIZAÇÃO DESTES.
        <br /><br />
        - LIMITAR SEUS DADOS QUANDO DESNECESSÁRIOS, EXCESSIVOS OU TRATADOS EM DESCONFORMIDADE COM A LEGISLAÇÃO ATRAVÉS DA ANONIMIZAÇÃO, BLOQUEIO OU ELIMINAÇÃO.
        <br /><br />
        - SOLICITAR A PORTABILIDADE DE SEUS DADOS, ATRAVÉS DE UM RELATÓRIO DE DADOS CADASTRAIS QUE A CGS TRATA A SEU RESPEITO.
        <br /><br />
        - ELIMINAR SEUS DADOS TRATADOS A PARTIR DE SEU CONSENTIMENTO, EXCETO NOS CASOS PREVISTOS EM LEI.
        <br /><br />
        - REVOGAR SEU CONSENTIMENTO, DESAUTORIZANDO O TRATAMENTO DE SEUS DADOS.
        <br /><br />
        - INFORMAR-SE SOBRE A POSSIBILIDADE DE NÃO FORNECER SEU CONSENTIMENTO E SOBRE AS CONSEQUÊNCIAS DA NEGATIVA.
        <br /><br />
        4.  COMO VOCÊ PODE EXERCER SEUS DIREITOS DE TITULAR?
        <br /><br />
        PARA EXERCER SEUS DIREITOS DE TITULAR, VOCÊ DEVE ENTRAR EM CONTATO COM A CGS OU SUAS SUBSIDIÁRIAS ATRAVÉS DOS SEGUINTES MEIOS DISPONÍVEIS:
        <br /><br />  
        SITE: CGSISTEMAS.COM.BR<br />
        EMAIL: CGSISTEMAS@CGSISTEMAS.COM.BR<br />
        TELEFONE: (83) 3142.0747<br />
        <br /><br />
        DE FORMA A GARANTIR A SUA CORRETA IDENTIFICAÇÃO COMO TITULAR DOS DADOS PESSOAIS OBJETO DA SOLICITAÇÃO, É POSSÍVEL QUE SOLICITEMOS DOCUMENTOS OU DEMAIS COMPROVAÇÕES QUE POSSAM COMPROVAR SUA IDENTIDADE. 
        <br /><br />
        5.  COMO E POR QUANTO TEMPO SEUS DADOS SERÃO ARMAZENADOS?
        <br /><br />
        SEUS DADOS PESSOAIS COLETADOS PELA CGS SERÃO UTILIZADOS E ARMAZENADOS DURANTE O TEMPO NECESSÁRIO PARA A PRESTAÇÃO DO SERVIÇO OU PARA QUE AS FINALIDADES ELENCADAS NA PRESENTE POLÍTICA DE PRIVACIDADE SEJAM ATINGIDAS, CONSIDERANDO OS DIREITOS DOS TITULARES DOS DADOS E DOS CONTROLADORES.<br />
        DE MODO GERAL, SEUS DADOS SERÃO MANTIDOS ENQUANTO A RELAÇÃO CONTRATUAL ENTRE VOCÊ E A CGS EXISTIR. FINDADO O PERÍODO DE ARMAZENAMENTO DOS DADOS PESSOAIS, ESTES SERÃO EXCLUÍDOS DE NOSSAS BASES DE DADOS OU ANONIMIZADOS, RESSALVADAS AS HIPÓTESES LEGALMENTE PREVISTAS NO ARTIGO 16 LEI GERAL DE PROTEÇÃO DE DADOS, A SABER:
        <br /><br />
        I – CUMPRIMENTO DE OBRIGAÇÃO LEGAL OU REGULATÓRIA PELO CONTROLADOR;<br />
        II – ESTUDO POR ÓRGÃO DE PESQUISA, GARANTIDA, SEMPRE QUE POSSÍVEL, A ANONIMIZAÇÃO DOS DADOS PESSOAIS;<br />
        III – TRANSFERÊNCIA A TERCEIRO, DESDE QUE RESPEITADOS OS REQUISITOS DE TRATAMENTO DE DADOS DISPOSTOS NESTA LEI; OU<br />
        IV – USO EXCLUSIVO DO CONTROLADOR, VEDADO SEU ACESSO POR TERCEIRO, E DESDE QUE ANONIMIZADOS OS DADOS.
        <br /><br />
        ISTO É, INFORMAÇÕES PESSOAIS SOBRE VOCÊ QUE SEJAM IMPRESCINDÍVEIS PARA O CUMPRIMENTO DE DETERMINAÇÕES LEGAIS, JUDICIAIS E ADMINISTRATIVAS E/OU PARA O EXERCÍCIO DO DIREITO DE DEFESA EM PROCESSOS JUDICIAIS E ADMINISTRATIVOS SERÃO MANTIDAS, A DESPEITO DA EXCLUSÃO DOS DEMAIS DADOS.<br />
        O ARMAZENAMENTO DE DADOS COLETADOS PELA CGS REFLETE O NOSSO COMPROMISSO COM A SEGURANÇA E PRIVACIDADE DOS SEUS DADOS. EMPREGAMOS MEDIDAS E SOLUÇÕES TÉCNICAS DE PROTEÇÃO APTAS A GARANTIR A CONFIDENCIALIDADE, INTEGRIDADE E INVIOLABILIDADE DOS SEUS DADOS. ALÉM DISSO, TAMBÉM CONTAMOS COM MEDIDAS DE SEGURANÇA APROPRIADAS AOS RISCOS E COM CONTROLE DE ACESSO ÀS INFORMAÇÕES ARMAZENADAS.
        <br /><br />
        6.  O QUE FAZEMOS PARA MANTER SEUS DADOS SEGUROS?
        <br /><br />
        PARA MANTERMOS SUAS INFORMAÇÕES PESSOAIS SEGURAS, USAMOS FERRAMENTAS FÍSICAS, ELETRÔNICAS E GERENCIAIS ORIENTADAS PARA A PROTEÇÃO DA SUA PRIVACIDADE.<br />
        APLICAMOS ESSAS FERRAMENTAS LEVANDO EM CONSIDERAÇÃO A NATUREZA DOS DADOS PESSOAIS COLETADOS, O CONTEXTO E A FINALIDADE DO TRATAMENTO E OS RISCOS QUE EVENTUAIS VIOLAÇÕES GERARIAM PARA OS DIREITOS E LIBERDADES DO TITULAR DOS DADOS COLETADOS E TRATADOS.
        <br /><br />
        ENTRE AS MEDIDAS QUE ADOTAMOS, DESTACAMOS AS SEGUINTES:<br /><br />
        • APENAS PESSOAS AUTORIZADAS TÊM ACESSO A SEUS DADOS PESSOAIS.<br />
        • O ACESSO A SEUS DADOS PESSOAIS É FEITO SOMENTE APÓS O COMPROMISSO DE CONFIDENCIALIDADE.<br />
        • SEUS DADOS PESSOAIS SÃO ARMAZENADOS EM AMBIENTE SEGURO E IDÔNEO.
        <br /><br />
        A CGS SE COMPROMETE A ADOTAR AS MELHORES POSTURAS PARA EVITAR INCIDENTES DE SEGURANÇA. CONTUDO, É NECESSÁRIO DESTACAR QUE NENHUMA PÁGINA VIRTUAL É INTEIRAMENTE SEGURA E LIVRE DE RISCOS. É POSSÍVEL QUE, APESAR DE TODOS OS NOSSOS PROTOCOLOS DE SEGURANÇA, PROBLEMAS DE CULPA EXCLUSIVAMENTE DE TERCEIROS OCORRAM, COMO ATAQUES CIBERNÉTICOS DE HACKERS, OU TAMBÉM EM DECORRÊNCIA DA NEGLIGÊNCIA OU IMPRUDÊNCIA DO PRÓPRIO USUÁRIO/CLIENTE.
        EM CASO DE INCIDENTES DE SEGURANÇA QUE POSSA GERAR RISCO OU DANO RELEVANTE PARA VOCÊ OU QUALQUER UM DE NOSSOS USUÁRIOS/CLIENTES, COMUNICAREMOS AOS AFETADOS E A AUTORIDADE NACIONAL DE PROTEÇÃO DE DADOS SOBRE O OCORRIDO, EM CONSONÂNCIA COM AS DISPOSIÇÕES DA LEI GERAL DE PROTEÇÃO DE DADOS.
        <br /><br />
        7.  COM QUEM SEUS DADOS PODEM SER COMPARTILHADOS?
        <br /><br />
        TENDO EM VISTA A PRESERVAÇÃO DE SUA PRIVACIDADE, A CGS NÃO COMPARTILHARÁ SEUS DADOS PESSOAIS COM NENHUM TERCEIRO NÃO AUTORIZADO.<br /> 
        SEUS DADOS PODERÃO SER COMPARTILHADOS COM NOSSOS PARCEIROS COMERCIAIS:<br />
        ESTES RECEBEM SEUS DADOS APENAS NA MEDIDA DO NECESSÁRIO PARA A PRESTAÇÃO DOS SERVIÇOS CONTRATADOS E NOSSOS CONTRATOS SÃO ORIENTADOS PELAS NORMAS DE PROTEÇÃO DE DADOS DO ORDENAMENTO JURÍDICO BRASILEIRO.<br />
        TODAVIA, NOSSOS PARCEIROS TÊM SUAS PRÓPRIAS POLÍTICAS DE PRIVACIDADE, QUE PODEM DIVERGIR DESTA.<br />
        ALÉM DISSO, TAMBÉM EXISTEM OUTRAS HIPÓTESES EM QUE SEUS DADOS PODERÃO SER COMPARTILHADOS, QUE SÃO:
        <br /><br />
        I – DETERMINAÇÃO LEGAL, REQUERIMENTO, REQUISIÇÃO OU ORDEM JUDICIAL, COM AUTORIDADES JUDICIAIS, ADMINISTRATIVAS OU GOVERNAMENTAIS COMPETENTES.
        <br />
        II – CASO DE MOVIMENTAÇÕES SOCIETÁRIAS, COMO FUSÃO, AQUISIÇÃO E INCORPORAÇÃO, DE FORMA AUTOMÁTICA
        <br />
        III – PROTEÇÃO DOS DIREITOS DA CGS EM QUALQUER TIPO DE CONFLITO, INCLUSIVE OS DE TEOR JUDICIAL.
        <br /><br />
        7.1.  TRANSFERÊNCIA INTERNACIONAL DE DADOS
        <br /><br />
        ALGUNS DOS TERCEIROS COM QUEM COMPARTILHAMOS SEUS DADOS PODEM SER LOCALIZADOS E OU POSSUIR INSTALAÇÕES LOCALIZADAS EM PAÍSES ESTRANGEIROS. NESSAS CONDIÇÕES, DE TODA FORMA, SEUS DADOS PESSOAIS ESTARÃO SUJEITOS À LEI GERAL DE PROTEÇÃO DE DADOS E ÀS DEMAIS LEGISLAÇÕES BRASILEIRAS DE PROTEÇÃO DE DADOS NESSE SENTIDO, A CGS SE COMPROMETE A SEMPRE ADOTAR EFICIENTES PADRÕES DE SEGURANÇA CIBERNÉTICA E DE PROTEÇÃO DE DADOS, NOS MELHORES ESFORÇOS DE GARANTIR E CUMPRIR AS EXIGÊNCIAS LEGISLATIVAS.<br />
        AO CONCORDAR COM ESSA POLÍTICA DE PRIVACIDADE, VOCÊ CONCORDA COM ESSE COMPARTILHAMENTO, QUE SE DARÁ CONFORME AS FINALIDADES DESCRITAS NO PRESENTE INSTRUMENTO.
        <br /><br />
        8.  ALTERAÇÃO DESTA POLÍTICA DE PRIVACIDADE
        <br /><br />
        A ATUAL VERSÃO DA POLÍTICA DE PRIVACIDADE FOI FORMULADA E ATUALIZADA PELA ÚLTIMA VEZ EM: 10.04.2021.<br />
        RESERVAMOS O DIREITO DE MODIFICAR ESSA POLÍTICA DE PRIVACIDADE A QUALQUER TEMPO, PRINCIPALMENTE EM FUNÇÃO DA ADEQUAÇÃO A EVENTUAIS ALTERAÇÕES FEITAS EM NOSSO SITE OU EM ÂMBITO LEGISLATIVO. RECOMENDAMOS QUE VOCÊ A REVISE COM FREQUÊNCIA.
        EVENTUAIS ALTERAÇÕES ENTRARÃO EM VIGOR A PARTIR DE SUA PUBLICAÇÃO EM NOSSO SITE E SEMPRE LHE NOTIFICAREMOS ACERCA DAS MUDANÇAS OCORRIDAS.<br />
        AO UTILIZAR NOSSOS SERVIÇOS E FORNECER SEUS DADOS PESSOAIS APÓS TAIS MODIFICAÇÕES, VOCÊ AS CONSENTE. 
        <br /><br />
        9.  RESPONSABILIDADE
        <br /><br />
        A CGS PREVÊ A RESPONSABILIDADE DOS AGENTES QUE ATUAM NOS PROCESSOS DE TRATAMENTO DE DADOS, EM CONFORMIDADE COM OS ARTIGOS 42 AO 45 DA LEI GERAL DE PROTEÇÃO DE DADOS.<br />
        NOS COMPROMETEMOS EM MANTER ESTA POLÍTICA DE PRIVACIDADE ATUALIZADA, OBSERVANDO SUAS DISPOSIÇÕES E ZELANDO POR SEU CUMPRIMENTO.<br />
        ALÉM DISSO, TAMBÉM ASSUMIMOS O COMPROMISSO DE BUSCAR CONDIÇÕES TÉCNICAS E ORGANIZATIVAS SEGURAMENTE APTAS A PROTEGER TODO O PROCESSO DE TRATAMENTO DE DADOS.<br />
        CASO A AUTORIDADE NACIONAL DE PROTEÇÃO DE DADOS EXIJA A ADOÇÃO DE PROVIDÊNCIAS EM RELAÇÃO AO TRATAMENTO DE DADOS REALIZADO PELA CGS, NOS COMPROMETEMOS A SEGUI-LAS. 
        <br /><br />
        10.1 ISENÇÃO DE RESPONSABILIDADE
        <br /><br />
        CONFORME MENCIONADO NO TÓPICO 6, EMBORA ADOTEMOS ELEVADOS PADRÕES DE SEGURANÇA A FIM DE EVITAR INCIDENTES, NÃO HÁ NENHUMA PÁGINA VIRTUAL INTEIRAMENTE LIVRE DE RISCOS. NESSE SENTIDO, A CGS NÃO SE RESPONSABILIZA POR:
        <br /><br />
        I – QUAISQUER CONSEQUÊNCIAS DECORRENTES DA NEGLIGÊNCIA, IMPRUDÊNCIA OU IMPERÍCIA DOS USUÁRIOS EM RELAÇÃO A SEUS DADOS INDIVIDUAIS. GARANTIMOS E NOS RESPONSABILIZAMOS APENAS PELA SEGURANÇA DOS PROCESSOS DE TRATAMENTO DE DADOS E DO CUMPRIMENTO DAS FINALIDADES DESCRITAS NO PRESENTE INSTRUMENTO.<br />
        DESTACAMOS QUE A RESPONSABILIDADE EM RELAÇÃO À CONFIDENCIALIDADE DOS DADOS DE ACESSO É DO USUÁRIO.
        <br /><br />
        II – AÇÕES MALICIOSAS DE TERCEIROS, COMO ATAQUES DE HACKERS, EXCETO SE COMPROVADA CONDUTA CULPOSA OU DELIBERADA DA CGS.
        DESTACAMOS QUE EM CASO DE INCIDENTES DE SEGURANÇA QUE POSSAM GERAR RISCO OU DANO RELEVANTE PARA VOCÊ OU QUALQUER UM DE NOSSOS USUÁRIOS/CLIENTES, COMUNICAREMOS AOS AFETADOS E A AUTORIDADE NACIONAL DE PROTEÇÃO DE DADOS SOBRE O OCORRIDO E CUMPRIREMOS AS PROVIDÊNCIAS NECESSÁRIAS.
        <br /><br />
        III – INVERACIDADE DAS INFORMAÇÕES INSERIDAS PELO USUÁRIO/CLIENTE NOS REGISTROS NECESSÁRIOS PARA A UTILIZAÇÃO DOS SERVIÇOS DA CGS; QUAISQUER CONSEQUÊNCIAS DECORRENTES DE INFORMAÇÕES FALSAS OU INSERIDAS DE MÁ-FÉ SÃO DE INTEIRAMENTE RESPONSABILIDADE DO USUÁRIO/CLIENTE.
        <br /><br />
        10. DÚVIDAS
        <br /><br />
        CASO TENHA DÚVIDAS SOBRE ESTA POLÍTICA DE PRIVACIDADE OU SOBRE OS DADOS PESSOAIS QUE TRATAMOS, VOCÊ PODE ENTRAR EM CONTATO CONOSCO PELOS SEGUINTES CANAIS:
        <br /><br />
        SITE: CGSISTEMAS.COM.BR<br />
        EMAIL: CGSISTEMAS@CGSISTEMAS.COM.BR<br />
        TELEFONE: (83) 3142.0747<br />
        ENDEREÇO: RUA ESPIRITO SANTO, 1350, LIBERDADE, CAMPINA GRANDE-PB.<br />
        <br /><br />
        VITRINE DIGITAL<br />
        Vitrinedigital.net
        <br /><br />
        CGS | CG SISTEMAS LTDA<br />
        cgsistemas.com.br
        <br /><br />
        <i class="lni lni-pause"></i>     
        <br /><br />
        </div>
        <div class="modal-footer">
          <a class="btn btn-white" style="border: 1px solid #c7c7c7; border-radius: 50px;" data-bs-dismiss="modal" aria-label="Close" data-dismiss="modal">Fechar</a>
        </div>
      </div>
    </div>
  </div>
  <!-- /Modal -->
</template>

<script>
    export default {
        name: "PrivacyModal"
    }
</script>