import http from '@/http-common';

class SearchDataService {

    async getSearch(parms){     
        console.log("PARAMS: " + JSON.stringify(parms));
        return await http.get("/v1/search", { params: parms } );
    }

}

export default new SearchDataService();