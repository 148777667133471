<template>
  <!-- Footer Section Start -->
  <footer>
      <!-- Footer Area Start -->
      <section class="footer-Content">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-md-6 col-sm-6 col-xs-6 col-mb-12">
              <div class="widget">
                <h3 class="block-title" style="color: rgb(255, 200, 3)">Iniciativa</h3>
                <div class="textwidget" style="text-align: justify">
                  <p>A plataforma vitrinedigitaloficial.com faz parte da INICIATIVA CONECTA e é uma ação do Sebrae-PB | PaqTc-PB | PMCG-SeDe | CDL | ACCG | FIEP | SENAI | IEL | Fecomércio | Sindilojas | CGS | Partage Shopping | Prefeitura de Conde, com objetivo do fortalecimento do comércio e negócios locais. O Sebrae-PB ainda disponibiliza consultorias e mentorias para as empresas participantes do projeto, com foco em marketing, o uso de plataformas digitais, redes sociais e whatsapp, além dos conceitos gerais e mais comuns no uso do e-commerce.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-1 col-md-6 col-sm-6 col-xs-6 col-mb-12">
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6 col-xs-6 col-mb-12">
              <div class="widget">
                <h3 class="block-title" style="color: rgb(255, 200, 3)">Integradores</h3>
                <ul class="contact-footer">
                  <li>
                    <a href="https://cgsdigital.com.br" style="color: rgb(255, 200, 3); font-size: 16px"><strong>CGS Digital</strong></a>
                  </li>
                  <li>
                    <a href="https://4cash.com.br" style="color: rgb(255, 200, 3); font-size: 16px"><strong>4CASH | pay</strong></a>
                  </li>
                </ul>         
              </div>
            </div>
            <div class="col-lg-1 col-md-6 col-sm-6 col-xs-6 col-mb-12">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-xs-6 col-mb-12">
              <div class="widget">
                <h3 class="block-title" style="color: rgb(255, 200, 3)">Contato</h3>
                <ul class="contact-footer">
                  <li>
                    <a href="https://api.whatsapp.com/send?1=pt_BR&phone=5583988700192" style="color: rgb(255, 200, 3); font-size: 16px"><strong><i class="lni-phone-handset"></i>&nbsp;&nbsp;Falar pelo WhatsApp</strong></a>
                  </li>
                  <li>
                    <a href="https://instagram.com/vitrinedigital_marketplace" style="color: rgb(255, 200, 3); font-size: 16px"><strong><i class="lni-instagram"></i>&nbsp;&nbsp;Siga-nos no Instagram</strong></a>
                  </li>
                </ul>
                <br/>
                <a style="color: #fff" href="#" id="btnPrivac2" data-target="#modalPrivacidade" data-bs-toggle="modal" data-bs-target="#modalPrivacidade"><strong>Política de Privacidade</strong></a>
                <br/>
                <a style="color: #fff" href="#" id="btnTermos2" data-target="#modalTermos" data-bs-toggle="modal" data-bs-target="#modalTermos"><strong>Termos de uso</strong></a>         
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Footer area End -->
      
      <!-- Copyright Start  -->
      <div id="copyright">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="site-info float-left">
                <p style="color: white; font-size: 14px;">Copyright © &nbsp;&nbsp;
                    <a href="http://www.paqtc.org.br/" target="_blank"><img src="assets/img/paqtc.svg" alt="logo"></a>
                    <a href="https://www.cgsistemas.com.br/" target="_blank">&nbsp;<img src="assets/img/cgs.svg" alt="logo"></a></p>
                    <br><br>
              </div>              
            </div>
          </div>
        </div>
      </div>
      <!-- Copyright End -->

    </footer>
    <!-- Footer Section End --> 

    <!-- Go to Top Link -->
    <a href="#" class="back-to-top">
    	<i class="lni-chevron-up"></i>
    </a>

</template>

<script>
  export default {
    name: "Footer" 
  }
</script>
