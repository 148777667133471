import http from '@/http-common';

class CitiesDataService {

    
    getAll(){                            
        return http.get("/v1/cities", null);
    }    

}

export default new CitiesDataService();