<template>
    <div ref="cookieModal" class="modal fade modcookie" id="cookieModal" tabindex="-1" role="dialog" aria-labelledby="modalCk" aria-hidden="true" style="bottom: 0 !important; top: auto">
        <div class="modal-dialog" role="document" style="max-width: 700px">
        <div class="modal-content">
            <div class="modal-body">
            <div class="notice d-flex justify-content-between align-items-center">
                <div class="cookie-text" style="padding-right: 30px; text-align: justify; font-weight: 500">Utilizamos cookies para oferecer melhor experiência, melhorar o desempenho e personalizar conteúdo. Ao continuar, você concorda com a nossa <a href="#" id="btnPrivac" data-target="#modalPrivacidade" data-bs-toggle="modal" data-bs-target="#modalPrivacidade">Política de Privacidade</a> e <a href="#" id="btnTermos" data-target="#modalTermos" data-bs-toggle="modal" data-bs-target="#modalTermos">Termos de uso</a>.</div>
                <div class="buttons d-flex flex-column flex-lg-row">
                <a @click="AcceptCookies()" class="btn btn-primary" data-bs-dismiss="modal" aria-label="Close" data-dismiss="modal" style="font-weight: 900; color: #fff">Concordar e fechar</a>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>

    <TermsModal />

    <PrivacyModal />

</template>

<style scoped>
    .modcookie {
        z-index: 9999;
    }
</style>

<script>

import TermsModal from '@/components/modals/TermsModal.vue';
import PrivacyModal from '@/components/modals/PrivacyModal';
import { useCookies } from "vue3-cookies";

export default {
    setup() {
        const { cookies } = useCookies();

        function AcceptCookies() {            
            this.cookies.set('acceptCookies','yes', '1y');            
            return true;
        }

        return { 
            cookies, 
            AcceptCookies 
        };
    },
    components: {
        TermsModal,
        PrivacyModal
    },
   
    mounted() {
        let acceptCookies = this.cookies.get("acceptCookies");
        if (!acceptCookies) {            
            window.$('#cookieModal').modal('show');
        }        
    }
}

</script>