<template>
    <FilterCityModal />
    <Header />     
    <!-- Main container Start -->  
    <div class="main-container section-padding">
      <div class="container">
        <div class="row">
          <!-- <div class="col-lg-3 col-md-12 col-xs-12 page-sidebar">
            <aside>
              <div class="widget categories">
                <h4 class="widget-title">Exibindo Loja(s).</h4>
              </div>
            </aside>
          </div> -->
          <div class="col-lg-12 col-md-12 col-xs-12 page-content">
            
              <!-- Result wrapper Start -->            
              <div class="tab-content">
                  <div id="grid-view" class="tab-pane fade active show">
                      <div :distance="distance" class="row results">
                          <!-- <infinite-scroll class="row" @infinite-scroll="retrieveSearch" 
                              :message="message"
                              :noResult="noResult"
                          > -->
                          <!-- Product filter End -->
                          <ShopItem />
                          <ShopItemSkeletor v-if="isPosting" :max-items="max_items" />
                          <infinite-loading :distance="distance" @infinite="load" />
                        </div>
                  </div>
              </div>            
              <!-- Result wrapper End -->
          </div>
        </div>
      </div>
    </div>
    <!-- Main container End -->     
    <Footer />
</template>

<script setup>

  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  import FilterCityModal from '@/components/modals/FilterCityModal.vue'
  import ShopItemSkeletor from '@/components/ShopItemSkeletor.vue'
  import ShopItem from '@/components/ShopItem.vue'

  import { ref, onBeforeMount, computed } from 'vue';
  import { useStore } from 'vuex';


  //let page = 1;
  let loading = false;
  let max_items = ref(18);

  const distance = ref(0);
  const store = useStore();

  const isPosting = computed(()=> store.state.isPosting );
  const page = computed(() => store.state.page.current);
  const loadMore = computed(() => store.state.page.loadMore);

  function IsMobile() {
      if( screen.width <= 760 ) {
          console.log("É mobile");
          return true;
      }
      else {
          console.log("Não é mobile");
          return false;
      }
  }

  if (IsMobile()){
      distance.value = 3000;
  } else {
      distance.value = 800;
  }    

  function getRequestParams(query, resource, page, pageSize, activity, orderby){
          let params = {};
          
          if (query) {
              params['q'] = query;
          }

          if (resource) {
              params['res'] = resource;
          }
          
          if (page) {
              params['page'] = page;
          }
          
          if (pageSize) {
              params['pSize'] = pageSize;
          }

          if (activity) {
              params['activity'] = activity;
          }

          if (orderby) {
              params['orderby'] = orderby;
          }

          return params;
  }

  async function retrieveSearch() {            
      if (loading === true || loadMore.value === false) return;
      loading = true;

      const urlParams = new URLSearchParams(window.location.search);   

      const params = getRequestParams(
          urlParams.get("q"),
          "shops",
          page.value,
          urlParams.get("psize"),
          urlParams.get("activity")
      );               
      
      max_items = params.pSize;

      let resultado = await store.dispatch('SEARCH', params);            

      console.log("REsultado: " + JSON.stringify(resultado));
      
      //page += 1;

      loading = false;                           
  }

    const load = async $state => {
        await retrieveSearch();
        $state.loaded();
    }

    //Events LifeCicle
    onBeforeMount(async ()=>{
        console.log("OnBeforeMount raised");
        await retrieveSearch();
    })

</script>