<template>
    <!-- Trending Categories Section Start -->
    <section class="trending-cat">
      <div class="container" style="text-align: center">
        <img class="img-fluid" src="assets/img/vitrine.logo.png" alt="">
        <br><br>
        <FilterCity />
        <br><br>
        <h1 class="section-title">Segmentos</h1>
        <div class="row">                
          
          <div v-for="item in items" :key="item.id" class="col-lg-2 col-md-6 col-sm-6 col-xs-12">
            <!-- href="#" data-toggle="modal" data-target="#modalAdulto" -->

            <!-- if ($value->isEmpty) {
                    echo 'href="#" data-toggle="modal" data-target="#modalIsEmpty"';
                } else if ($value->isAdult) {
                    echo 'href="#" data-toggle="modal" data-target="#modalIsAdult"';
                } else if (REWRITE_LINKS_ENABLE) {
                    echo 'href="shops?activity=' . $value->id . '"';
                } else {
                    echo 'href="index.php?controller=shops&activity=' . $value->id . '"';
                }                  -->
            <a :href="getHref(item)" :data-toggle="getDataToggle(item)" :data-target="getDataTarget(item)">            
              <div class="box">
                <div class="icon">
                  <img class="img-fluid" :src="'assets/img/activities/' + item.id + '.png'" alt="">
                </div>
                <h4>{{ item.name }}</h4>
                <!-- <strong>189 Ads</strong> -->
              </div>
            </a>
          </div>
        
        </div>
      </div>
    </section>
    <!-- Trending Categories Section End -->
    
    <!-- Featured Section Start -->
    <section class="featured section-padding">
      <div class="container" style="text-align: center">
        <img class="img-fluid" src="assets/img/vitrine.separa.svg" alt="">
<!--         <h1 class="section-title">Estabelecimentos</h1>
        <div style="text-align: center">
          <a class="btn btn-common" href="seg/index.html">
            <i class="lni-home"></i>
            Ver todos os estabelecimentos
          </a>
        </div> -->
      </div>
    </section>
    <!-- Featured Section End -->
</template>

<script>

import FilterCity from '@/components/FilterCity.vue'
//import ActivitiesDataService from '@/services/ActivitiesDataService';
import { useStore } from "vuex";
import { computed, onBeforeMount } from 'vue';

export default {

    setup(){       

        const store = useStore();

        let items = computed(() => {
            return store.state.activities;
        });


        function getHref(item) {
            return (!item.isEmpty && !item.isAdult) ? "/shops?activity=" + item.id : 'javascript:;'
        }

        function getDataToggle(item) {
            return (item.isEmpty || item.isAdult) ? "modal" : ''
        }

        function getDataTarget(item) {
            if (item.isEmpty) return "#modalIsEmpty"
            if (item.isAdult) return "#modalIsAdult"     
            return ''      
        }      

        async function retrieveActivities(){            

          await store.dispatch('GET_ACTIVITIES', null);
            
        }
       
       //Events LifeCycle
        onBeforeMount(()=>{
            retrieveActivities();                
        });
        

        return {
            items,
            getHref,
            getDataToggle,
            getDataTarget,
            retrieveActivities,
        }

    },
    components: {
      FilterCity
    }
}

</script>
