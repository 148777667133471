import http from '@/http-common';

class ActivitiesDataService {

    
    getAll(){                            
        return http.get("/v1/activities", null);
    }    

}

export default new ActivitiesDataService();