<template>
    <AdultModal />
    <EmptyModal />
    <FilterCityModal />
    <Header />
    <Activities />
    <Footer />
</template>

<script>
// @ is an alias to /src
import AdultModal from '@/components/modals/AdultModal.vue'
import EmptyModal from '@/components/modals/EmptyModal.vue'
import FilterCityModal from '@/components/modals/FilterCityModal.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Activities from '@/components/Activities.vue'

export default {
  name: 'Home',
  components: {
    AdultModal,
    EmptyModal,
    FilterCityModal,
    Header,
    Footer,
    Activities
  }  
}
</script>