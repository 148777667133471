<template>                                                                                                      
  <div v-for="item in shops" :key="item.id_shop" class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
    <div class="featured-box">
      <figure>
        <a :href="item.shop_link" target="_blank"><img class="img-fluid" :src="item.shop_link + item.logo_banner_link" alt=""></a>
      </figure>
      <div class="feature-content">
        <div class="tg-product"></div>
        <h4><a :href="item.shop_link" target="_blank">{{ item.name }}</a></h4>
        <span>Segmento: <a :href="'shops?activity=' + item.id_activity">{{ item.activity }}</a></span>
        <div class="tg-product" style="color: #999">
          {{ item.city + ' - ' + item.state }} <br/>
          {{ item.address1 }}
        </div>
        <div class="btn-list">
          <a class="btn btn-common" :href="item.shop_link" target="_blank">
            <i class="lni-home"></i>
            Visitar
          </a>
        </div>
      </div>
    </div>
  </div>  

  <div v-if="shops.lenght==0" class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <span v-if="activity">Nenhum Loja no segmento "<b>{{ activity }}"</b></span>      
        <span v-if="!activity">Nenhuma Loja foi localizado com "<b>{{ query }}"</b></span>                      
  </div>     
               
</template>

<script>
import { computed, onBeforeMount } from 'vue';

import { useStore } from 'vuex';

export default {
   
    setup(){
        
        //const skeletor = useSkeletor();
        // Set the shimmer config
        //skeletor.shimmer = false;

        const store = useStore();
      
        const shops = computed(() => store.state.shops);

        // //Events LifeCycle
        onBeforeMount(()=>{
          console.log("Carregou componente ShopItem ");                  
        });

        return {
            shops            
        }
    }
}
</script>
