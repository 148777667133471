<template>
<!-- Modal -->
    <div class="modal fade" id="localizacaoModal" tabindex="-1" role="dialog" aria-labelledby="modalLocalizacaoCenter" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header" style="background: #082d86; color: #fff;">
            <h5 class="modal-title" id="exampleModalLongTitle">Informe a sua localização!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="color: #fff">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            A sua <span style="font-weight: bold">cidade</span> é:
            
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-8">
                    <input type="text" class="form-control" autocomplete="off" name="s" :value="cityField" id="city_uf" disabled>
                  </div>
                  <div class="col-md-4">
                    <button @click="confirma()" type="button" class="btn btn-primary" style="width: 100%">Continuar</button>
                  </div>
                </div>
              </div>
            </div>

            <div style="text-align: center; margin: 20px 0 20px 0; font-weight: bold">Ou selecione a localização mais próxima de você!</div>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6" style="margin: 0 0 10px 0" v-for="(city, index) in cities.left" :key="index">
                    <button @click="changeCity('city_' + index)" :id="'city_' + index" type="button" class="btn btn-primary" style="width: 100%; background: transparent; color: #000">{{ city.city + '-' + city.state }}</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-6" style="margin: 0 0 10px 0" v-for="(city, index) in cities.right" :key="index">
                    <button @click="changeCity('city_' + index)" :id="'city_' + index" type="button" class="btn btn-primary" style="width: 100%; background: transparent; color: #000">{{ city.city + '-' + city.state }}</button>
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>   
</template>

<script>

    import { computed } from "vue";
    import { useCookies } from "vue3-cookies";
    import { useStore } from "vuex";
    
    export default {      
        setup(){
            
            

            const { cookies } = useCookies();
            const store = useStore();

            let cityField = computed(() => {
                if (!store.state.location.city || !store.state.location.uf) return '';
                return store.state.location.city + '-' + store.state.location.uf;
            });

            let cities = computed(()=> {
                
                let ret = { left: [], right: [] }
                let totalCities = store.state.cities.length;
                let halfCities = Math.round(totalCities / 2);
                
                ret.left = store.state.cities; //.slice(0, halfCities);
                //ret.right = store.state.cities.slice(halfCities, store.state.cities.length);                

                console.log("### METADE DE CITY: " + halfCities + " LEFT: " + ret.left + " RIGHT: " + ret.right);

                return ret
            });            

            console.log(JSON.stringify(cities.value));

            async function getCities(){
                await store.dispatch('GET_CITIES');            
            }

            async function confirma() {              

                if (store.state.location.city && store.state.location.uf) {
                    this.cookies.remove("city");
                    this.cookies.remove("uf");

                    this.cookies.set('city',store.state.location.city);  
                    this.cookies.set('uf',store.state.location.uf);  
                }
                
                console.log("RAISE: ON_CHANGE_LOCATION");

                await store.dispatch('ON_CHANGE_LOCATION'); 

                window.$('#localizacaoModal').modal('toggle');
                
                return true;

            }

            async function changeCity(name) {
                let btn = document.getElementById(name);
                
                store.state.location.city = btn.innerText.split('-')[0];
                store.state.location.uf = btn.innerText.split('-')[1];

                console.log(store.state.location);

                store.commit("changeLocation", store.state.location);

                if (store.state.location.city && store.state.location.uf) {
                    this.cookies.remove("city");
                    this.cookies.remove("uf");

                    this.cookies.set('city',store.state.location.city, '6m');  
                    this.cookies.set('uf',store.state.location.uf, '6m');  
                }                

                await store.dispatch('ON_CHANGE_LOCATION'); 

                window.$('#localizacaoModal').modal('toggle');

                return true;
            }

            return { 
              cookies,
              cityField,
              cities,
              confirma,
              changeCity,
              getCities
            };
        },

        async mounted() {

          const store = useStore();

          store.state.location.city = this.cookies.get("city");
          store.state.location.uf = this.cookies.get("uf");

          if (!store.state.location.city || !store.state.location.uf){
            window.$('#localizacaoModal').modal('show');
          }
          
          console.log("Iniciou........##");

          await new Promise(r => setTimeout(r, 2000));

          await this.getCities();

        }
    }
</script>
