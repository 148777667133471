import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'
import Shops from '@/views/Shops.vue'
import Search from '@/views/Search.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/shops',
    name: 'Shops',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Shops
  },
  {
    path: '/search',
    name: 'Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Search
  }
//   {
//     path: '/suporte',
//     name: 'Suporte',
//     component: Suporte
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.    
//     //component: () => import(/* webpackChunkName: "about" */ '../views/Suporte.vue')
//   },
//   {
//     path: '/contato',
//     name: 'Contato',
//     component: Contato
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.    
//     //component: () => import(/* webpackChunkName: "about" */ '../views/Suporte.vue')
//   }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",  
  // scrollBehavior(to, from, savedPosition) {
  //   //Faz a o scrool rolar pro início da página.
  //   return { left: 0, top: 0 };
  // }
})

export default router