import { createStore } from 'vuex'
import SearchDataService from '@/services/SearchDataService';
import CitiesDataService from '../services/CitiesDataService';
import ActivitiesDataService from '@/services/ActivitiesDataService';

export default createStore({
  state: {
    location: {
      city: null,
      uf: null
    },
    page: {
      pSize: 18,
      current: 1,
      loadMore: true
    },
    isPosting: false,
    cities: [],
    activities: [],
    shops: [],
    products: []
  },
  mutations: {
      changeLocation(state, newState) {
        
          console.log(state);
          
          state.location = newState;      
      },

      setIsPosting(state, newState) {
        state.isPosting = newState;
      },

      incrementCurrentPage(state) {
        state.page.current++;
      },

      setCurrentPage(state, newState) {
        state.page.current = newState;
      },            

      setLoadMore(state, newState) {
        state.page.loadMore = newState;
      },

      fillActivities(state, newState) {
          state.activities = newState;
      },
      
      fillCities(state, newState) {
          state.cities.push(...newState);
          console.log("Add items to state.cities");
      },

      appendProducts(state, newState) {        
        state.products.push(...newState);
        console.log("Add items to state.products: " + (state.shops));
      },

      clearProducts(state) {        
        state.products = [];        
      },
      
      appendShops(state, newState) {                    
          state.shops.push(...newState);
          console.log("Add items to state.shops: " + (state.shops));
      },

      clearShops(state) {        
        state.shops = [];        
      },

  },
  actions: {
    
    async SEARCH({ commit }, params) {

      try {

          console.log("## PARAMS: " + JSON.stringify(params));

          //app.appContext.config.globalProperties.$VDIsPosting = true;
          commit('setIsPosting', true);

          console.log("Start " + this.state.isPosting);

          const result = await SearchDataService.getSearch(params);

          if(result.data != null) {
              
              const { objects } = result.data;   
              console.log("Listados: " + this.state.shops.length + " Chegaram: " + objects.length);
              console.log(result.data);
              console.log(result.data.next_show)
                              
              if (params.res && params.res == 'products') {
                  commit('appendProducts', objects);
                  console.log("Call appendProducts from actions.SEARCH");      
              } else if (params.res && params.res == 'shops'){
                  commit('appendShops', objects);
                  console.log("Call appendShops from actions.SEARCH");      
              }

              if (result.data.next_show == true) {
                commit('incrementCurrentPage');
                commit('setLoadMore', true);
              } else {
                commit('setLoadMore', false);
              }
              commit('setIsPosting', false);
              //count = max_items;        
              //if (objects.length > 0) page.value = page.value + 1;
          }
      } catch (error) {
        commit('setIsPosting', false);
          // noResult = true;
          // message = "Error loading data";
      }    

      console.log("Last " + this.state.isPosting);

    },

    async GET_CITIES({ commit }){
       try {

          const result = await CitiesDataService.getAll();

          if(result.data != null) {
              
              const { objects } = result.data;   
              
              console.log("Listados: " + this.state.shops.length + " Chegaram: " + objects.length);

              commit('fillCities', objects);   
              
              
          }

       } catch (error) {
          
          console.log("Error: " + error);

       }
    },

    async GET_ACTIVITIES({ commit }){

      var RemoveItem = function (element, key, value) {
        if (value == undefined)
            return;
        console.log("KEY: " + key);
        for (var i in element) {
            if (element[i][key] == value) {
              element.splice(i, 1);
            }
        }
      }

      ActivitiesDataService.getAll()
      .then((response) => {
          const activities = response.data                    
                                                  
          if (this.state.location.city != "Conde" || this.state.location.uf != "PB") {
              RemoveItem(activities, "id", "29");                          
          } else {
              RemoveItem(activities, "id", "27");
              RemoveItem(activities, "id", "30");
          }

          commit('fillActivities', activities);

          console.log(response.data);
      })
      .catch((e) => {
          console.log(JSON.stringify(e));
      });
    },

    async ON_CHANGE_LOCATION(){
        //GET_ACTIVITIES
        await this.dispatch('GET_ACTIVITIES');         
    }

  },
  modules: {
  }
})
