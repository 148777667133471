<template>    
    <!-- Header Area wrapper Starts -->
    <header id="header-wrap">

        <!-- Navbar Start -->
        <nav class="navbar navbar-expand-lg fixed-top scrolling-navbar" style="background: rgb(8, 45, 134) !important;" >
            <div class="container">
            <div class="navbar-header">
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar" aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                    <span class="lni-menu"></span>
                    <span class="lni-menu"></span>
                    <span class="lni-menu"></span>
                </button>
                <a href="/" class="navbar-brand"><img src="assets/img/logo.svg" alt="" style="max-width: 200px; margin-top: 10px"></a>
            </div>
            <div class="search-bar sb-new" style="width: 34vw; margin-bottom: 10px; border: 2px solid #082d86; border-radius: 5px; margin-top: 20px !important">
                <fieldset>                
                    <!--<form autocomplete="off" class="search-form" action="https://localhost:7296/v1/search" method="get" onsubmit="return validateCityAndUF();">-->
                    <form class="search-form" @submit.prevent="onSubmit">                        
                        <div class="form-group tg-inputwithicon search-bar-new">
                        <i class="lni-tag"></i>
                        <input type="text" v-model="query" name="q" class="form-control" placeholder="O que está procurando?" style="text-transform: none !important">
                        </div>
                        <div class="form-group tg-inputwithicon categories-new">
                        <i class="lni-layers"></i>
                        <div class="tg-select">
                        <select name="r" v-model="resource">
                            <option value="products">&nbsp;Produtos</option>
                            <option value="shops">&nbsp;Lojas</option>
                            </select>
                        </div>
                        </div>
                        <button type="submit" class="submit" style="display: none;"></button>
                        <a href="javascript:;" onclick="$('.submit').click();" class="btn btn-common" type="button"><i class="lni-search"></i></a>
                    </form>
                </fieldset>
            </div>
            <div class="collapse navbar-collapse" id="main-navbar">
                <ul class="navbar-nav mr-auto"></ul>
                <ul class="sign-in">
                <li class="nav-item">
                    <a class="nav-link" href="https://vitrinedigitaloficial.com/parceiro" target="_blank">
                    <strong><i class="lni-user"></i>&nbsp;Portal do Parceiro</strong>
                    </a>
                </li>
                </ul>
            </div>
            </div>

            <!-- Mobile Menu Start -->
            <ul class="mobile-menu">
            <li>
                <a href="https://vitrinedigital.campinagrande.br/parceiro" target="_blank"><strong><i class="lni-user"></i>&nbsp;Portal do Parceiro</strong></a>
            </li>
            </ul>
            <!-- Mobile Menu End -->

        </nav>
        <!-- Navbar End -->

        <!-- Hero Area Start -->
        <div id="hero-area">
            <div class="overlay"></div>
            <div class="container">
            <div class="row">
                <div class="col-md-12 col-lg-12 col-xs-12 text-center">
                <div class="contents">
        <!--                 <h1 class="head-title">Bem-vindo(a) ao<br/><span style="color: rgb(255, 200, 3)">Vitrine Digital Campina Grande</span></h1>
                    <p>Digite o que deseja abaixo!</p> -->
                    <div class="search-bar sb1">
                        <fieldset>
                            <form autocomplete="off" class="search-form" @submit.prevent="onSubmit">                            
                                <div class="form-group tg-inputwithicon search-bar-new">
                                    <i class="lni-tag"></i>                            
                                    <input type="text" v-model="query" name="q" class="form-control" placeholder="O que está procurando?" style="text-transform: none !important">
                                </div>
                                <div class="form-group tg-inputwithicon categories-new">
                                    <i class="lni-layers"></i>
                                    <div class="tg-select">
                                    <select name="r" v-model="resource">
                                        <option value="products">&nbsp;Produtos</option>
                                        <option value="shops">&nbsp;Lojas</option>
                                    </select>
                                    </div>
                                </div>
                                <button type="submit" class="m-submit" style="display: none;"></button>
                                <a href="javascript:;" onclick="$('.m-submit').click();" class="btn btn-common btn-m-search" type="button"><i class="lni-search"></i></a>
                            </form>
                        </fieldset>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        <!-- Hero Area End -->

    </header>
    <!-- Header Area wrapper End -->
</template>

<style>

    .navbar-collapse {
        flex-grow: 0;
    }

    @media only screen and (max-width: 1199px) {
        .sb-new {
        display: none;
        }
    }

    @media only screen and (min-width: 1200px) {
        .sb1 {
        display: none;
        }
    }


   /* button search */
    .btn-m-search {
        height: 60px !important;
        -webkit-appearance: none !important;
        border-radius: 0 !important;
    }

    @media screen and (max-width: 768px) {
        
        #hero-area {
            background: #082d86 !important;
        }
        
    }

</style>

<script setup>
    //import { getCurrentInstance } from 'vue';
    import router from '@/router';    
    import { onBeforeMount, ref, getCurrentInstance } from 'vue';
    import { useStore } from 'vuex';   
              

    let query = ref('');
    let resource = ref('products');

    const store = useStore();
    const { emit } = getCurrentInstance();

    const urlParams = new URLSearchParams(window.location.search);
    
    //const instance = getCurrentInstance();

    async function onSubmit() {
            
        console.log( "@res: " + resource.value);    
        
        store.commit("setLoadMore", true);
        store.commit('setCurrentPage', 1);

        router.push({
            name: "Search",
            // params: {
            //     city: "Campina Grande",
            //     uf: "PB"
            // },                    
            query: { 
                q: query.value,
                r: resource.value
            }
        });

        //emit("onSearchSubmit");
        
        emit("onResourceChanged", resource.value);                    

        if (window.location.href.indexOf("search") > -1){
            console.log("Carrega do header retrieveSearch()")            
            await retrieveSearch();
        }                

        query.value = '';

        topFunction();

        
        //resource = resource.value;
    }

    function getRequestParams(query, resource, page, pageSize, activity, orderby){
        let params = {};
        
        if (query) {
            params['q'] = query;
        }

        if (resource) {
            params['res'] = resource;
        }
        
        if (page) {
            params['page'] = page;
        }
        
        if (pageSize) {
            params['pSize'] = pageSize;
        }

        if (pageSize) {
            params['activity'] = activity;
        }

        if (orderby) {
            params['orderby'] = orderby;
        }

        return params;
    }

    async function retrieveSearch(){  
        
        if (resource.value == "products") {
            store.commit("clearProducts");
        } else {
            store.commit("clearShops");
        }              

        const urlParams = new URLSearchParams(window.location.search);            
        
        const params = getRequestParams(
            query.value,
            resource.value,
            1,
            18,
            urlParams.get("activity")                
        );                

        //resource.value = params.res;                
        await store.dispatch('SEARCH', params);            
                                
    }

    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    onBeforeMount(() => {                
            let res = urlParams.get("r")  ;
            if (res!=null){
                resource.value = res;
            }      
    });           
            
</script>